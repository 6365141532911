<template>
	<b-modal id="cancel-asset-issuance" title="Cancel Asset Issuance" ref="modal" ok-title="Proceed" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="confirm-message">
			Are you sure you want to
			<b>cancel</b> this issuance <b class="numFont">{{ selAssetIssuance.issuanceId }}</b>?
		</div>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// API
import assetIssuanceApi from '@/api/assetIssuanceApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'cancel-asset-issuance',
	components: {
		Loading,
	},
	data() {
		return {
			selAssetIssuance: {},

			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		issuanceId() {
			return this.selAssetIssuance.issuanceId;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelAssetIssuance', (assetIssuance) => {
			this.selAssetIssuance = assetIssuance;
		});
	},
	methods: {
		handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let issuanceId = this.selAssetIssuance.issuanceId;
			let cancelledBy = `${this.loggedUser.firstName} ${this.loggedUser.lastName}`;
			let notes = ` Asset Issuance ${issuanceId} was cancelled by ${cancelledBy};`;

			this.selAssetIssuance.notes += notes;
			this.selAssetIssuance.status = config.assetIssuanceStatus.CANCELLED;
			this.selAssetIssuance['dateCancelled'] = DateUtil.getCurrentTimestamp();
			this.selAssetIssuance['cancelledBy'] = this.loggedUser.id;

			this.cancelAssetIssuance(this.selAssetIssuance);
		},
		async cancelAssetIssuance(assetIssuance) {
			try {
				let param = {
					currUserId: this.loggedUser.id,
					assetIssuances: [],
				};
				param.assetIssuances.push(assetIssuance);

				let { data } = await assetIssuanceApi.saveAssetIssuances(param);

				if (data.isSuccess) {
					this.$toaster.success(`Asset Issuance "${this.issuanceId}" was cancelled successfully.`);
					EventBus.$emit('onCloseSaveAssetIssuance', data.assetIssuances[0]);
					this.$refs.modal.hide();

				} else {
					this.$toaster.error(`Error cancelling Asset Issuance "${this.issuanceId}". Please try again later.`);
				}

			} catch (_error) {
				this.$toaster.error(`Error cancelling Asset Issuance "${this.issuanceId}". Please try again later.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onReset() {
			// do nothing
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelAssetIssuance');
	},
};
</script>