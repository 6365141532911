<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-tag"></i> Asset Issuance</b-card-title>
			<b-card-sub-title>Manages the issuance of company assets to their employees</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Date From">
											<b-form-datepicker v-model="filterBy.dateFrom" locale="en" reset-button
												label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" :date-disabled-fn="dateFromDisabled" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Date To">
											<b-form-datepicker v-model="filterBy.dateTo" locale="en" reset-button
												label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" />
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text"
												:options="filterByOptions.companyItems" :reduce="(company) => company.value"
												v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Asset Type">
											<v-select class="style-chooser" label="text"
												:options="filterByOptions.assetTypeItems"
												:reduce="(assetType) => assetType.value" v-model="filterBy.assetType">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="User">
											<v-select class="style-chooser" label="text"
												:options="filterByOptions.userItems" :reduce="(user) => user.value"
												v-model="filterBy.user">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Status">
											<b-form-select v-model="filterBy.status" :options="statusOptions"
												class="mr-2" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Asset Code">
											<b-form-input id="assetCode" name="Asset Code" type="search" class="numFont"
												v-model="filterBy.assetCode" v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }" />
											<span v-show="errors.has('Asset Code')" class="help-block">{{
												errors.first('Asset Code') }}</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Issuance Id">
											<b-form-input id="issuanceId" name="Issuance Id" type="search" class="numFont"
												v-model="filterBy.issuanceId"
												v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }" />
											<span v-show="errors.has('Issuance Id')" class="help-block">{{
												errors.first('Issuance Id') }}</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item v-b-modal.add-asset-issuance v-show="!isViewer">
								Add Asset Issuance
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
									Export Asset Issuances in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
									Export Asset Issuances to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table ref="issuancesTable" show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
					:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
					:sort-direction="sortDirection" responsive>

					<template v-slot:cell(issuanceId)="row">
						<span class="numFont">
							{{ row.item.issuanceId }}
						</span>
					</template>

					<template v-slot:cell(issuedTo)="row">
						{{ row.item.issuedTo }}
					</template>

					<template v-slot:cell(assetCode)="row">
						<span class="numFont">
							<strong>{{  row.item.assetCode  }}</strong>
						</span> <br />
						<span class="asset-name-display">{{ '('+ row.item.assetName  +')' }}</span>
					</template>

					<template v-slot:cell(dateIssued)="row">{{
						row.item.dateIssued
						? showFormattedDateTime(row.item.dateIssued)
						: '-'
					}}</template>

					<template v-slot:cell(status)="row">
						<AssetIssuanceRowStatus :item="row.item" />
					</template>

					<template v-slot:cell(actions)="row">
						<AssetIssuanceRowActions :row="row" :isSuperAdmin="isSuperAdmin" :isViewer="isViewer" />
					</template>

					<template slot="row-details" slot-scope="row">
						<AssetIssuanceDetailsView :row="row" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" />
					</b-col>
				</b-row>
			</div>
		</b-card>

		<!-- Modals here -->
		<AddAssetIssuance :assetTypeOptions="filterByOptions.assetTypeItems" :allCompaniesObj="allCompaniesObj"
			:allUsersObj="allUsersObj" :allAssetIssuancesObj="allAssetIssuancesObj" />
		<EditAssetIssuance :assetTypeOptions="filterByOptions.assetTypeItems" :allCompaniesObj="allCompaniesObj"
			:allUsersObj="allUsersObj" :allAssetIssuancesObj="allAssetIssuancesObj" />
		<CancelAssetIssuance />
		<ReturnAssetIssuance />
	</div>
</template>

<script>
// Components
import AddAssetIssuance from '@/views/asset/assetIssuance/AddAssetIssuance';
import EditAssetIssuance from '@/views/asset/assetIssuance/EditAssetIssuance';
import CancelAssetIssuance from '@/views/asset/assetIssuance/CancelAssetIssuance';
import ReturnAssetIssuance from '@/views/asset/assetIssuance/ReturnAssetIssuance';
import AssetIssuanceRowActions from '@/views/asset/assetIssuance/AssetIssuanceRowActions';
import AssetIssuanceDetailsView from '@/views/asset/assetIssuance/AssetIssuanceDetailsView';
import AssetIssuanceRowStatus from '@/views/asset/assetIssuance/AssetIssuanceRowStatus';

// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API
import assetIssuanceApi from '@/api/assetIssuanceApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'asset-issuance',
	components: {
		AddAssetIssuance,
		EditAssetIssuance,
		CancelAssetIssuance,
		ReturnAssetIssuance,
		AssetIssuanceRowActions,
		AssetIssuanceDetailsView,
		AssetIssuanceRowStatus,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'issuanceId',
					label: 'Issuance ID',
					sortable: true,
				},
				{
					key: 'issuedTo',
					label: 'Issued To',
					sortable: true,
				},
				{
					key: 'assetCode',
					label: 'Asset',
					sortable: true,
				},
				{
					key: 'dateIssued',
					label: 'Date Issued',
					sortable: true,
				},
				{
					key: 'status',
					label: 'Status',
				},
				{
                    key: 'actions',
                    thClass: 'text-center'
                }
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				company: config.companyDefaultValue,
				assetType: config.assetTypeDefaultValue,
				user: config.userDefaultValue,
				status: null,
				assetCode: '',
				issuanceId: '',
			},
			filterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				company: config.companyDefaultValue,
				assetType: config.assetTypeDefaultValue,
				user: config.userDefaultValue,
				status: null,
				assetCode: '',
				issuanceId: '',
			},
			prevFilter: {},
			filterByOptions: {
				companyItems: [],
				assetTypeItems: [],
				userItems: [],
			},

			allCompaniesObj: {},
			allAssetTypesObj: {},
			allUsersObj: {},

			allAssetIssuancesObj: {},
			selAssetIssuance: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		dateFrom() {
			const dateTo = moment();
			const dateFrom = dateTo.add(-30, 'days');
			return dateFrom.format('YYYY-MM-DD');
		},
		dateTo() {
			return moment().format('YYYY-MM-DD');
		},

		statusOptions() {
			return [
				{
					value: null,
					text: ' - Please select - ',
				},
				{
					value: 'On-Going',
					text: 'On-Going',
				},
				{
					value: 'Returned',
					text: 'Returned',
				},
				{
					value: 'Cancelled',
					text: 'Cancelled',
				},
			];
		},

		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				'Issuance ID': 'issuanceId',
				Company: 'company',
				'Asset Type': 'assetType',
				'Asset Code': 'assetCode',
				'Asset Name': 'assetName',
				'Issued To': 'issuedTo',
				Description: 'description',
				'Date Issued': 'Date Issued',
				'Issued By': 'issuedBy',
				'Date Returned': 'Date Returned',
				'Received By': 'receivedBy',
				'Date Cancelled': 'Date Cancelled',
				'Cancelled By': 'cancelledBy',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'AssetIssuance-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Filter Access
				if (this.$store.getters.isScanner) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// show loading indicator
				this.isLoading = true;

				// Company Dropdown Values
				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.filterByOptions.companyItems = DropDownItemsUtil.retrieveActiveCompanyItems(this.allCompaniesObj);

				// Asset Type Dropdown Values
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
				this.filterByOptions.assetTypeItems = DropDownItemsUtil.retrieveAssetTypeItems(this.allAssetTypesObj, true);

				// User Dropdown Values
				this.allUsersObj = { ...this.$store.getters.users };
				this.filterByOptions.userItems = DropDownItemsUtil.retrieveActiveUserItems(this.allUsersObj);

				// Set Default Filter Options
				if (!this.isSuperAdmin) {
					this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
				}
				this.defaultFilterBy.dateFrom = this.dateFrom;
				this.defaultFilterBy.dateTo = this.dateTo;
				this.resetFilters();

				await this.retrieveData();
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
			
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseAddAssetIssuance', (assetIssuancesArr) => {
			this.addAssetIssuanceToTable(assetIssuancesArr);
		});
		EventBus.$on('onCloseSaveAssetIssuance', (assetIssuanceObj) => {
			this.addAssetIssuanceToTable([assetIssuanceObj]);
		});
	},
	methods: {
		addAssetIssuanceToTable(assetIssuancesArr) {
			if (!_.isEmpty(assetIssuancesArr)) {
				assetIssuancesArr.forEach((assetIssuance) => {
					this.allAssetIssuancesObj[assetIssuance.id] = assetIssuance;
				});

				this.filterTableContent();
			}
		},
		filterTableContent() {
			let filteredObj = { ...this.allAssetIssuancesObj };
			_.forEach(filteredObj, (assetIssuance, id) => {
				if (
					this.filterBy.company.id !== null &&
					this.filterBy.company.id !== assetIssuance.companyId
				) {
					delete filteredObj[id];
				}

				if (
					this.filterBy.assetType.id !== null &&
					this.filterBy.assetType.id !== assetIssuance.assetTypeId
				) {
					delete filteredObj[id];
				}

				if (
					this.filterBy.user.id !== null &&
					this.filterBy.user.id !== assetIssuance.userId
				) {
					delete filteredObj[id];
				}

				if (
					!_.isEmpty(this.filterBy.issuanceId) &&
					this.filterBy.issuanceId !== assetIssuance.issuanceId
				) {
					delete filteredObj[id];
				}

				if (
					!_.isEmpty(this.filterBy.assetCode) &&
					this.filterBy.assetCode !== assetIssuance.assetCode
				) {
					delete filteredObj[id];
				}
			});

			this.processAssetIssuances(filteredObj);
		},
		processAssetIssuances(assetIssuances) {
			this.items = Object.values(assetIssuances);
			
			this.items.forEach((item) => {
				item['issuedTo'] = item.firstName + ' ' + item.lastName;

				item['Date Issued'] = DateUtil.getFormattedDateWithTime(
					item.dateIssued
				);
				item['Date Returned'] = DateUtil.getFormattedDateWithTime(
					item.dateReturned
				);
				item['Date Cancelled'] = DateUtil.getFormattedDateWithTime(
					item.dateCancelled
				);

				// remove show details
				delete item._showDetails;
			});
			this.items = _.sortBy(this.items, ['dateIssued']);
			this.items.reverse();
			this.totalRows = this.items.length;

			// refresh table
			if (this.$refs.issuancesTable) {
				this.$refs.issuancesTable.refresh();
			}
		},

		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},
		isValidAssetCode(assetCode) {
			return _.isEmpty(assetCode) ||
				ValidationUtil.isValidAssetCode(this.allAssetTypesObj, assetCode);
		},
		validateFilter() {
			let isValid = true;

			if (_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo)) {
				this.$toaster.warning('Date From and Date To are required.');
				isValid = false;
			} else if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
				(!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
				this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
				isValid = false;
			} else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
				isValid = false;
			} else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90) {
				this.$toaster.warning('Invalid Date Range. Data range is allowed up to 90 days difference.');
				isValid = false;
			} else if (!this.isValidAssetCode(this.filterBy.assetCode)) {
				this.$toaster.warning(`Invalid Asset Code. "${this.filterBy.assetCode}" doesn't follow any of your asset tagging format.`);
				isValid = false;
			} else if (!ValidationUtil.isAlphaNumeric(this.filterBy.issuanceId)) {
				this.$toaster.warning('Invalid Issuance ID. Please enter a valid Issuance ID');
				isValid = false;
			}

			return isValid;
		},

		async onFilterRequest() {
			if (!this.validateFilter()) {
				return;
			}

			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				await this.retrieveData();
				this.prevFilter = { ...this.filterBy };
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };
			}
		},
		async retrieveData() {
			try {
				// show loading indicator
				this.isLoading = true;

				let filter = { ...this.filterBy };
				filter.companyId = this.loggedUserCompany.id;
				filter.fromTimestamp = DateUtil.startDateTimeStamp(
					new Date(filter.dateFrom)
				);
				filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

				if (this.isSuperAdmin) {
					const { data } = await assetIssuanceApi.getAssetIssuances(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allAssetIssuancesObj = data.assetIssuances;
				} else {
					const { data } = await assetIssuanceApi.getAssetIssuances(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allAssetIssuancesObj = data.assetIssuances;
				}

				this.filterTableContent();
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		// UTILS
		showFormattedDateTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
	beforeDestroy() {
		EventBus.$off('onCloseAddAssetIssuance');
		EventBus.$off('onCloseSaveAssetIssuance');
	},
};
</script>

<style scoped>
.asset-name-display {
	font-size: small !important;
	font-style: italic;
}
</style>